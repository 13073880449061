import {backendApi} from "../../backend-api-sr"

export const compositionThickness = (() =>{
    const fetchMachType = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-mach-type'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchMachid = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-mach-id'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchBrand = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-brand'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchThickName = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-thick-name'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchThick = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-thick'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchWidth = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-width'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchGrade = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-grade'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchCoatMass = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-coat-mass'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchColor = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-color'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchQuality = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/filter-quality'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchIndex = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchSupplierPft = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/get-supplier-pft'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchDetail = ( async (subPath) => {
        var url = '/api/v3/sr/composition-thickness/get-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {fetchMachType, fetchMachid, fetchBrand, fetchThickName, fetchThick, fetchWidth, fetchGrade, fetchCoatMass, fetchColor, fetchQuality, fetchIndex, fetchSupplierPft, fetchDetail};
})()