<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Machine Type </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="machineType"
                                    :items="machineTypes" default="" item-value="mach_type" item-text="mach_type" label="Machine Type"
                                      clearable>
                                      <!-- @change="(event) => getMachId(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Machine Id </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="machId"
                                    :items="machIds" default="" item-value="mach_id" item-text="mach_id" label="Machine ID"
                                      clearable>
                                      <!-- @change="(event) => getBrand(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Brand </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="brand"
                                    :items="brands" default="" item-value="brand" item-text="brand" label="Brand"
                                      clearable>
                                      <!-- @change="(event) => getThick(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Thick Name <strong style="color:red;">*</strong><span></span> </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="thickName"
                                    :items="thickNames" default="" item-value="Thick_name" item-text="Thick_name" label="Thick Name"
                                    @change="(event) => getThick(event)"  clearable>
                                </v-autocomplete>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Thick </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="thick"
                                    :items="thicks" default="" item-value="thick" item-text="thick" label="Thick"
                                      clearable>
                                      <!-- @change="(event) => getWidth(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Width </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="width"
                                    :items="widths" default="" item-value="width" item-text="width" label="Width"
                                      clearable>
                                      <!-- @change="(event) => getGrade(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Grade </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="grade"
                                    :items="grades" default="" item-value="grade_id" item-text="grade_id" label="Grade"
                                      clearable>
                                      <!-- @change="(event) => getCoatMass(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Coat Mass </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="coatMass"
                                    :items="coatMasses" default="" item-value="coat_mass" item-text="coat_mass" label="Coat Mass"
                                      clearable>
                                      <!-- @change="(event) => getColor(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Color </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="color"
                                    :items="colors" default="" item-value="color" item-text="color" label="Color"
                                      clearable>
                                      <!-- @change="(event) => getQuality(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Quality Id </h6> -->
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="quality"
                                    :items="qualities" default="" item-value="quality_id" item-text="quality_id" label="Quality Id"
                                    clearable>
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">Start Period <strong style="color:red;">*</strong><span></span></h6> -->
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <!-- <h6 class="blue-lcd mb-1 pa-1">To Period </h6> -->
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="month" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn block class="border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()"><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn block class="border-12" style="padding: 19px;" small color="success" elevation="2" >
                                    <v-icon>mdi-file-excel</v-icon>

                                    <download-excel
                                        class="text"
                                        :fetch           = "exportExcel"
                                        :fields="headersColumn"
                                        :before-generate = "startDownload"
                                        :before-finish   = "finishDownload">
                                        Export
                                    </download-excel> </v-btn>
                            </v-col>
                        </v-row>

                        
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Summary</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2" v-if="tableVisible">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <v-card-title>
                        <h6 class="cyan-text text-darken-3 m-0">Summary Supplier Perfomance % of PFT</h6>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="pftSumHeader"
                                    :items="pftSumData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="3"
                                    fixed-header
                                    hide-default-footer
                                    :divider="true"
                                    :light="true"
                                >        
                                <!-- <template v-slot:detailData.wgt="{ item }">
                                    {{ formatNumber(item.wgt) }}
                                </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2" v-if="tableVisible">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <v-card-title>
                        <h6 class="cyan-text text-darken-3 m-0">Production Summary Per Supplier</h6>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="prodSumHeader"
                                    :items="prodSumData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="4"
                                    fixed-header
                                    hide-default-footer
                                    :divider="true"
                                    :light="true"
                                >        
                                <!-- <template v-slot:detailData.wgt="{ item }">
                                    {{ formatNumber(item.wgt) }}
                                </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-5" v-if="tableVisible">
            <v-col class="col-12">
                <v-card outlined>
                    <v-card-title>
                        <h6 class="cyan-text text-darken-3 m-0">Detail</h6>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="formattedDetailData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="782"
                                    :divider="true"
                                    :light="true"
                                >        
                                <!-- <template v-slot:detailData.wgt="{ item }">
                                    {{ formatNumber(item.wgt) }}
                                </template> -->
                                
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {compositionThickness} from "../../../backend-api/sr/mill_data_analysis/composition_thickness"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data(){
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'SR',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Mill Data Analysis',
                    disabled: true,
                    href: '/admin/sr',
                },
                {
                    text: 'Thickness Composition',
                    disabled: true
                }
            ],
            // filter
            machineType:'',
            machineTypes:[],
            machId:'',
            machIds:[],
            brand:'',
            brands:[],
            thickName:'',
            thickNames:[],
            thick:'',
            thicks:[],
            width:'',
            widths:[],
            grade:'',
            grades:[],
            coatMass:'',
            coatMasses:[],
            color:'',
            colors:[],
            quality:'',
            qualities:[],
            date_from:'',
            modal_from:false,
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            // chart
            x: window.matchMedia("(max-width: 991px)"),
            // TODO kurang clear next filter
            // Datatable
            tableVisible:false,
            prodSumHeader:[],
            prodSumData:[],
            pftSumHeader:[],
            pftSumData:[],
            header:[
                {
                    text: 'Origin Id',
                    align: 'start',
                    value: 'origin_id', 
                    align:'left'},
                { text: 'Mach Type', value: 'mach_type', align:'left'},
                { text: 'Mach ID', value: 'mach_id' , align:'left'},
                { text: 'Brand', value: 'brand' , align:'left'},
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Grade', value: 'grade_id' , align:'left'},
                { text: 'Coat Mass', value: 'coat_mass' , align:'right'},
                { text: 'Color', value: 'color' , align:'left'},
                { text: 'Quality', value: 'quality_id' , align:'left'},
                { text: 'Weigth', value: 'wgt' , align:'right'},
            ],
            headersColumn:{
                'Origin Id': 'origin_id', 
                'Mach Type': 'mach_type', 
                'Mach ID': 'mach_id',
                'Tahun' : 'tahun',
                'Bulan' : 'bulan',
                'Brand' : 'brand',
                'Thick Name': 'Thick_name',
                'Thick': 'thick',
                'Width': 'width',
                'Grade': 'grade_id',
                'Coat Mass': 'coat_mass',
                'Color': 'color',
                'Quality' : 'quality_id',
                'Weigth' : 'wgt'
            },
            detailData:[],
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMachType()
        await this.getMachId()
        await this.getBrand()
        await this.getThickName()
        await this.getThick()
        await this.getWidth()
        await this.getGrade()
        await this.getCoatMass()
        await this.getColor()
        await this.getQuality()
        this.$store.dispatch('setOverlay', false)
    },
    computed:{
        formattedDetailData() {
            return this.detailData.map(item => ({
                ...item,
                thick:this.$store.getters.convertToCurrencyUs(item.thick),
                width:this.$store.getters.convertToCurrencyUs(item.width),
                wgt: this.$store.getters.convertToCurrencyUs(item.wgt/1000)
            }));
        }
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        sumField(key) {
            // sum data in give key (property)
            return this.prodSumData.reduce((a, b) => a + (b[key] || 0), 0)
        },
        async getMachType(event){
            const respData = await compositionThickness.fetchMachType('', null, false, false, false)
            if (respData.status === 200) {
                this.machineTypes = respData.data.data
            }
        },
        async getMachId(event){
            const respData = await compositionThickness.fetchMachid(`?mach_type=${this.machineType}`, null, false, false, false)
            if (respData.status === 200) {
                this.machIds = respData.data.data
            }
        },
        async getBrand(event){
            const respData = await compositionThickness.fetchBrand(`?mach_type=${this.machineType}&mach_id=${this.machId}`, null, false, false, false)
            if (respData.status === 200) {
                this.brands = respData.data.data
            }
        },
        async getThickName(event){
            const respData = await compositionThickness.fetchThickName(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}`, null, false, false, false)
            if (respData.status === 200) {
                this.thickNames = respData.data.data
            }
        },
        async getThick(event){
            const respData = await compositionThickness.fetchThick(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}`, null, false, false, false)
            if (respData.status === 200) {
                this.thicks = respData.data.data
            }
        },
        async getWidth(event){
            const respData = await compositionThickness.fetchWidth(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}`, null, false, false, false)
            if (respData.status === 200) {
                this.widths = respData.data.data
            }
        },
        async getGrade(event){
            const respData = await compositionThickness.fetchGrade(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}`, null, false, false, false)
            if (respData.status === 200) {
                this.grades = respData.data.data
            }
        },
        async getCoatMass(event){
            const respData = await compositionThickness.fetchCoatMass(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}`, null, false, false, false)
            if (respData.status === 200) {
                this.coatMasses = respData.data.data
            }
        },
        async getColor(event){
            const respData = await compositionThickness.fetchColor(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}`, null, false, false, false)
            if (respData.status === 200) {
                this.colors = respData.data.data
            }
        },
        async getQuality(event){
            const respData = await compositionThickness.fetchQuality(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}&color=${this.color}`, null, false, false, false)
            if (respData.status === 200) {
                this.qualities = respData.data.data
            }
        },
        async getPullData(event){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = ''
                var periodEnd = ''
                var container = "container1";
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
                const respData = await compositionThickness.fetchIndex(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}&coat_mass=${this.coatMass}&quality_id=${this.quality}&color=${this.color}&periodStart=${periodStart}&periodEnd=${periodEnd}`, null, false, false, false)
                if (respData.status === 200) {
                    var inputJson = respData.data.data.bar
                    // var light = inputJson.map(({ period, WGT_CAP }) => ({ label: period, y: parseFloat(WGT_CAP) }));
                    // var medium = inputJson.map(({ period, wgt_end }) => ({ label: period, y: parseFloat(wgt_end) }));
                    // var heavy = inputJson.map(({ period, wgt_end }) => ({ label: period, y: parseFloat(wgt_end) }));
                    // console.log(inputJson);
                    //console.log(respData.data.data)
                    const transformedData = {};

                    inputJson.forEach(item => {
                        const thickName = item.Thick_name.toLowerCase();
                        if (!transformedData[thickName]) {
                            transformedData[thickName] = [];
                        }
                        // console.log(transformedData);
                       
                        transformedData[thickName].push({
                            "label": item.periode,
                            "y": parseFloat(item.wgt)/1000
                        });
                    });
                    var heavy = (transformedData.heavy) ? transformedData.heavy : []
                    var medium = (transformedData.medium) ? transformedData.medium : []
                    var light = (transformedData.light) ? transformedData.light : []
                    // console.log(heavy)
                    // console.log(medium)
                    // console.log(light)
                    await this.fetchSumSupplierProductionPft()
                    // di backend filternya t buangin
                    this.showSumSupplierProduction(respData.data.data.totalProd)
                    this.$store.dispatch('setOverlay', false)
                    await this.getChart(light, medium, heavy, "Light", "Medium", "Heavy", container, 'column');
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)

                    await this.getChart([], [], [], "Light", "Medium", "Heavy", container, 'column');

                    return false
                }
            }
        },
        async fetchSumSupplierProductionPft(){
            var periodStart = ''
            var periodEnd = ''
            periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
            periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            // &quality_id=${this.quality}
            // const respData = await compositionThickness.fetchSupplierPft(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}&coat_mass=${this.coatMass}&color=${this.color}&periodStart=${periodStart}&periodEnd=${periodEnd}`, null, false, false, false)
            const respData = await compositionThickness.fetchSupplierPft(`?periodStart=${periodStart}&periodEnd=${periodEnd}`, null, false, false, false)
            console.log('supplier pft', respData)
            if (respData.status === 200) {
                this.showSumSupplierPft(respData.data.data)
            } else {
                this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                    return false
            }
        },
        showSumSupplierProduction(res){
            const y_result = Object.entries(res.reduce((a,{Thick_name,origin_id,wgt})=>
            {
                a[Thick_name] = a[Thick_name] ?? {}
                a[Thick_name][origin_id] = this.$store.getters.convertToCurrencyUs(wgt/1000)
                return a
            },{})).map(([Thick_name,vals])=>({Thick_name, ...vals}))
                
            const allKeys = new Set();
            const allKeysArray = [];
            y_result.forEach(obj => {
                Object.keys(obj).forEach(key => {
                    if (key !== "Thick_name" && !allKeys.has(key)) {
                        allKeys.add(key);
                        allKeysArray.push(key);
                    }
                });
            });

            allKeysArray.sort();
            allKeysArray.unshift("Thick_name");
            const totalObj = {
                "Thick_name": "Total"
            };

            allKeysArray.slice(1).forEach(key => {
                totalObj[key] = y_result.reduce((sum, obj) => {
                    console.log('jumlah')
                    console.log(key)
                    console.log(obj)
                    const value = obj[key] ? parseFloat(obj[key].replace(/,/g, '')) || 0 : 0;
                    console.log(value)
                    return sum + value;
                }, 0).toFixed(4);
                totalObj[key] = this.$store.getters.convertToCurrencyUs(totalObj[key])
            });

            // Add the totalObj to the data array
            y_result.push(totalObj);

            this.prodSumHeader = allKeysArray.map(key => ({
                text: key,
                sortable:false,
                value: key,
                align: 'left'
            }));
            this.prodSumData = y_result
            console.log('allKeysArray')
            console.log(allKeysArray)
            
            const customSortOrder = ["Light", "Medium", "Heavy", "Total"];
            // Custom sorting function
            function customSort(a, b) {
                const aIndex = customSortOrder.indexOf(a.Thick_name);
                const bIndex = customSortOrder.indexOf(b.Thick_name);

                if (aIndex === -1) {
                    return 1; // If not found, move to the end
                }
                if (bIndex === -1) {
                    return -1; // If not found, move to the end
                }

                return aIndex - bIndex;
            }

            // Sort the data using the custom sorting function
            this.prodSumData.sort(customSort);

            console.log('Final')
            console.log(this.prodSumData);
        },
        showSumSupplierPft(res){
            const y_result = Object.entries(res.reduce((a,{Thick_name,origin_id,ratio})=>
            {
                a[Thick_name] = a[Thick_name] ?? {}
                a[Thick_name][origin_id] = this.$store.getters.convertToCurrencyUs(ratio)
                return a
            },{})).map(([Thick_name,vals])=>({Thick_name, ...vals}))
                
            const allKeys = new Set();
            const allKeysArray = [];
            y_result.forEach(obj => {
                Object.keys(obj).forEach(key => {
                    if (key !== "Thick_name" && !allKeys.has(key)) {
                        allKeys.add(key);
                        allKeysArray.push(key);
                    }
                });
            });

            allKeysArray.sort();
            allKeysArray.unshift("Thick_name");
            this.pftSumHeader = allKeysArray.map(key => ({
                text: key,
                value: key,
                sortable:false,
                align: 'left'
            }));
            this.pftSumData = y_result
            const customSortOrder = ["Light", "Medium", "Heavy"];
            // Custom sorting function
            function customSort(a, b) {
                const aIndex = customSortOrder.indexOf(a.Thick_name);
                const bIndex = customSortOrder.indexOf(b.Thick_name);

                if (aIndex === -1) {
                    return 1; // If not found, move to the end
                }
                if (bIndex === -1) {
                    return -1; // If not found, move to the end
                }

                return aIndex - bIndex;
            }

            // Sort the data using the custom sorting function
            this.pftSumData.sort(customSort);
        },
        
        async getDetail(label, data){
            this.$store.dispatch('setOverlay', true)
            // console.log(label)
            // console.log(data.dataPoint)
            var tName = label
            var period = data.dataPoint.label
            // belum usable
            const respData = await compositionThickness.fetchDetail(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${tName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}&coat_mass=${this.coatMass}&quality_id=${this.quality}&color=${this.color}&period=${period}`, null, false, false, false)
           // console.log(respData)
            this.detailData = respData.data.data
            this.tableVisible = true
            this.$store.dispatch('setOverlay', false)
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(dt1, dt2, dt3, name1, name2, name3, container, type){
            // console.log(dt1)
            // console.log(dt2)
            // console.log(name1)
            // console.log(name2)
            // console.log(container)
            // console.log(type)
            var self = this;

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Ton",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                {
                    click: async function(e){
                        await self.getDetail(name1, e)
                    },
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name1,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#F29605",
                    // yValueFormatString: "##0.00",
                    yValueFormatString: "#,###.##",
                },
                {
                    click: async function(e){
                        await self.getDetail(name2, e)
                    },
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name2,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#CF8D25",
                    // yValueFormatString: "##0.00",
                    yValueFormatString: "#,###.##",
                },
                {
                    click: async function(e){
                        await self.getDetail(name3, e)
                    },
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name3,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    // color: "#9F621E",
                    // yValueFormatString: "##0.00",
                    yValueFormatString: "#,###.##",
                }]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            console.log("chart option", chart.options)

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },

        async exportExcel(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
                const respData = await compositionThickness.fetchDetail(`?mach_type=${this.machineType}&mach_id=${this.machId}&brand=${this.brand}&Thick_name=${this.thickName}&thick=${this.thick}&width=${this.width}&grade_id=${this.grade}&coat_mass=${this.coatMass}&quality_id=${this.quality}&color=${this.color}&periodStart=${periodStart}&periodEnd=${periodEnd}`, null, false, false, false)
                
                return respData.data.data.map(item => ({
                    ...item,
                    thick:this.$store.getters.convertToCurrencyUs(item.thick),
                    width:this.$store.getters.convertToCurrencyUs(item.width),
                    wgt: this.$store.getters.convertToCurrencyUs(item.wgt/1000)
                }))
            }
        },

        startDownload(){
        //    this.loading = true
        //    this.disabledExport=true
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
            }
        },
        finishDownload(){
            // this.loading = false
            // this.disabledExport=false
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch:{},
}
</script>
<style scoped>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>